import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgDaemmung01 from "../../assets/leistungen/waerme01.jpg"
import imgDaemmung02 from "../../assets/leistungen/waerme02.jpg"
import imgDaemmung03 from "../../assets/leistungen/waerme03.jpg"
import imgDaemmung04 from "../../assets/leistungen/waerme04.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Wärmedämmung für Dach &amp; Fassade"
      Content="Wer im Winter keine Mütze auf dem Kopf hat, bekommt kalte Ohren.
        Im Sommer hingegen sorgt die fehlende Kopfbedeckung für ein
        überhitztes „Oberstübchen“. Ganz ähnlich reagieren Dächer und
        Fassaden. Wenn die Dämmung fehlt oder zu schwach ist, wird es
        schnell ungemütlich im ganzen Haus. Abhilfe schafft eine gut
        dimensionierte Dämmung aus geeignetem Material."
    />
    <LeistungBox
      Color="gray"
      Image={imgDaemmung01}
      IsImageLeft={true}
      Title="Zwischensparrendämmung"
      Content="Die aus Mineralwolle, Steinwolle oder auch natürlichen Materialien
        verwendete Dämm-Variante kommt heute am häufigsten zum Einsatz.
        Hierbei werden die Zwischenräume der Dachsparren mit Dämmmaterial
        gefüllt. Raumseitig wird eine Dampsperrfolie angebracht, die die
        Dämmung vor Feuchtigkeit aus den Innenräumen schützt. Leider
        werden hier vor allem seitens der Heimwerker immer wieder
        gravierende Fehler gemacht, die oft zu Feuchteschäden im Bereich
        der Zwischensparrendämmung sowie der Holzkonstruktion führen.
        Verfaulte Dachstühle sind schon nach wenigen Jahren das Ergebnis."
    />
    <LeistungBox
      Color="white"
      Image={imgDaemmung02}
      IsImageLeft={false}
      Title="Aufsparrendämmung"
      Content="Bei dieser Technik wird eine durchgehende Dämmschicht oberhalb der
        Sparren angebracht. Im Vergleich zur Zwischensparrendämmung wird
        dadurch die Dämmleistung nicht durch Sparrenanteile geschwächt.
        Für diese Form der Dämmung wird auf die Sparren eine
        Luftdichtigkeitsbahn befestigt und ans Mauerwerk angeschlossen.
        Auf diese Luftdichtigkeitsbahn werden druckfeste Dämmplatten aus
        Steinwolle, PUR/PIR oder Holzfaser lückenlos über das gesamte Dach
        verlegt. Auf die Dämmplatten wiederum erfolgt der weitere Aufbau
        mittels Konterlatte, Dachlatte und Eindeckung. Auf Wunsch können
        bei dieser Variante die Dachsparren sichtbar bleiben, um sie
        architektonisch für den Innenausbau zu nutzen."
    />
    <LeistungBox
      Color="gray"
      Image={imgDaemmung03}
      IsImageLeft={true}
      Title="Fassadendämmung"
      Content="Auch hier gilt: Eine gute Dämmung schafft ein angenehmes
        Wohnklima. Eine Fassadendämmung mit darüber liegender Bekleidung
        aus Blech, Holz, Schiefer oder Verbundmaterialien verbessert nicht
        nur das Wohnklima, sondern schützt auch die Fassade vor
        Verwitterung und trägt somit zur Werterhaltung des Hauses bei."
    />
    <LeistungBox
      Color="white"
      Image={imgDaemmung04}
      IsImageLeft={false}
      Title="Flachdachdämmung"
      Content="Bei der Planung und Ausführung von Flachdächern, insbesondere
        Balkonen, Terrassen und begrünten Flachdächern gelten besondere
        Anforderungen. Die Dämmungen müssen druckfest, formstabil und
        temperaturbeständig sein. Um das Oberflächenwasser bei
        Flachdächern ohne bauseitigem Gefälle zu den Entwässerungspunkten
        zu führen, gibt es maßgefertigte Gefälledämmplatten. Diese werden
        nach einem festgelegten Verlegeplan auf die Dachfläche gebracht.
        Sie sorgen in Kombination mit der richtigen Abdichtung für einen
        fachgerechten und funktionstüchtigen Dachaufbau."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
